import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
// import zhUserCenter from './UserCenter/cn.js'
// import zhStorage from './Storage/cn.js'
// import zhBuyerCenter from './BuyerCenter/cn.js'
// import zhSellerCenter from './SellerCenter/cn.js'
// import zhPartnerCenter from './PartnerCenter/cn.js'
// import zhCommercialTenant from './CommercialTenant/cn.js'
import locale_cn from '../../../i18n_locales/zh_cn.js'

const cn = {
	addressManager: {
		title: '地址管理'
	},
	message: {
		'hello': '你好，世界',
	},
	meta: {
		main: "-WMS",
	},
	tips: {
		loading: '加载中...',
		Submitting: "正在提交..",
		tipsTitle: '提示',

		errorData: "抱歉，请求数据有误",
		requestErrorData: "抱歉，请求数据有误，请重试",

		checkSubmitData: "请检查待提交的数据。",

		submitSuccess: "提交成功！",
		successfullyDelete: "删除成功",

		submitError: "抱歉，提交失败,请重试",
		submitRequestError: "抱歉，提交失败,请重试！",

		queryErrorFailed: "抱歉，查询失败",
		queryRequestFailed: "抱歉，查询失败！",

		// successAddShopCart: "成功加入购物车",
		// ErrorAddShopCart: "添加到购物车失败，请重试",
		// RequestFailedAddShopCart: "添加到购物车，请求失败!",

		no_data: "暂无数据",
		no_null: "暂无",
		Please_Enter: "请输入",
		confirmSubmit: "确定提交吗？",
		Please_Sel: "请先选择",
		Please_Sel_date: "请选择日期",

		no_match_label: "没有匹配的面单",
		no_sel_lgs_no_use: '当前选择的KeyCode为不可用状态',
		no_sel_express_company_order: '未知的快递公司和单号',

		noLoginUserMsg: "必须为登录才能操作，是否立即去登录?",
		// noEnMsg: "必须为厂家才能进行该操作，是否认证为厂家?",
		// noLoginMsg: "必须为厂家才能进行该操作，是否登录?",
		// noShopMsg: "厂家认证通过才能进行商户认证",
		// authChangeLogin: "认证信息有变更，请重新登录",
		long_TipsActionHotTable_1: "1、可下载模板后，直接导入Excel；同Excel操作，复制粘贴数据；",
		long_TipsActionHotTable_1_1: "1、同Excel操作；也可复制粘贴Excel中数据；",
		long_TipsActionHotTable_2: "2、鼠标右键可添加、删除、编辑表格；",
		long_TipsActionHotTable_3: "3、保存时请删除空白行；双击可选择或选中数据；",

		long_accountInfo: "预付账户目前为线下充值，请转账完毕后保留转账记录；并线上申请【去充值】并告知客服，我司收到申请后会第一时间处理。",
		long_WarehouseShipments_1: '只有全部为 已提交 状态数据才可操作',
		long_WarehouseDropShipping_1: '只有状态为草稿、已提交、已审核才可以提交打单',

		long_InWarehouse_1: '只有全部为 未入库 状态数据才可操作',
	},
	common: {

		HYTX: 'HYTX',
		HeadPortrait: '头像',

		Commodity_types: "商品类型",
		All: "全部",
		// Steel: '钢材',
		// Wood: '木材',
		// Cotton: '棉花',
		// Cotton_Yarn: '棉纱',
		// CottonAndCottonYarn: '棉/纱',
		// VehicleHY: '车',
		// Vehicle: '车',
		// Alcohol: '酒',
		// Tea: '茶',
		// Finance: '金融',
		Storage: '仓储',
		Other: '其他',
	},
	FormMsg: {
		// 20191118
		placeholder_commodity_name: "商品标题最多支持60个汉字，超出将不再显示超出后的内容",
		Cancel: "取消",
		Sure: "确定",

		Save: "保存",
		Submit: "提交",

		back: "返回",

		Modify: "修改",
		Setting: "设置",

		Open: "开启",
		Close: "关闭",
		Refresh: "刷新",

		Search: "搜索",
		Query: "查询",

		Operation: "操作", //操作
		Delete: "删除", //删除
		Add: "添加", //添加
		Edit: "编辑",
		Select: "选择",
		// Modify:"修改",//修改

		confirm_Delete: "确定删除吗？",

		picture: "图片", //图片
		preview: "预览", //预览

		details_View: "查看明细", //查看明细
		upload_attachment: "上传附件", //上传附件

		Mobile_phone: "手机号",
		Verification_Code: "验证码",
		placeholder_Code: "请输入验证码",
		Get_Code: "获取验证码",
		Sent: "验证码已发送",
		seconds: "秒后可重新获取验证码",

		placeholder_must_pwd: "密码必须为6-18位，且至少包含一位字母和一位数字",
		placeholder_phone_msg: "短信验证码已发送至您的手机，5分钟内有效，请查收！",

		Failed_captcha: "获得验证码失败", //"获得验证码失败"
		error_captcha: "验证码存在错误", //验证码存在错误
		Password_inconsistency: "密码不一致", //密码不一致

		Please_Enter: "请输入",
		Please_select: "请选择",
		Please_Enter_Sel: "请输入或选择",
		Please_upload: "请上传",



		ver_generation_card: "请输入二代身份证",
		ver_number: "请输入数字",
		ver_integer: "请输入整数",
		ver_3_decimal: "请最多保留三位小数",
		ver_phone_number: "请输入手机号码",
		ver_place: "请选择收货地省市",

		Select_date: "选择日期", //,"选择日期"
		Select_start_date: "选择开始日期", //,"选择开始日期"
		Select_end_date: "选择结束日期", //,"选择结束日期"
		Now: "今天", //今天
		yesterday: "昨天", //昨天
		A_Week_Ago: "一周前", //一周前

		last_a_week: "最近7天",
		last_a_mon: "最近30天",
		last_three_mon: "最近90天",

		Select_date_time: "选择日期时间", //,"选择日期"

		Select_data_msg: "请先选择要操作的数据", //请先选择要操作的数据
		Select_long_pdfSplit: "请输入单号(一行一个单号,且按PDF顺序匹配)",
		Select_long_whLabelFileUpload_1: "1、若该订单已完成线下快递面单生成，请在此处上传对应面单！",
		Select_long_whLabelFileUpload_2: "2、订单单号和文件名要相同才可以匹配",
		Select_long_LgsFedexTrackQuery_1: "可多个查询(一行一个,最多30)",
		Select_long_wh: '请先选择仓库',
		Select_long_status: '请选择状态',
		Select_long_out_wh: "请选择出库仓库",
		Select_long_goods_source: "请选择货物来源",
		Select_long_goods_transport: "请选择要运送的货物",
	},
	module: {
		HyAreaCodeSel: {
			"DefaMsg": '常用国家/地区',
			"errorMsg": '手机区域代码请求失败',
		},
		HyCityCountyLinkage: {
			province: "省份", //省份
			city: "城市", //城市
			district_county: "区/县", //区/县
			warehouse: "仓库", //仓库
			Failed_province_information: "获取省份信息失败", //'获取省份信息失败'
			Failed_city_information: "获取城市信息失败", //'获取城市信息失败'
			Failed_district_information: "获取区县信息失败", //'获取区县信息失败'
			Failed_warehouse_information: "获取仓库信息失败", //'获取仓库信息失败'
		},
		upload: {
			Choose_File: "选取文件", //选取文件
			Upload_server: "上传到服务器", //上传到服务器
			uploaded_over: "上传文件大小不能超过", //上传文件大小不能超过 2M
			fail_upload: "上传失败", //上传失败
			Please_upload_format: "请上传合适格式的文件", //'请上传合适格式的文件'
			files_exceeded: "文件超出个数限制", //'文件超出个数限制'

			Please_sel_file: "请先选择文件", //Please select the file first// 请先选择文件

			// Failed_district_information:"The size of the uploaded file cannot exceed",//'文件尺寸不能大于'
			Unknown_image: "未知的图片地址", //'未知的图片地址'
			Unknown_file: "未知的文件", //this.$t('module.upload.Unknown_file')

			uploading: "正在上传", //'正在上传'
			filename: "文件名", //'文件名'
			Click_upload: "点击上传", //''点击上传
			open_error_img: "打开上传文件有误", //''打开上传文件有误，请联系客服
			open_browser_error_img: "浏览器版本过低不支持文件方法，请升级浏览器或联系客服", //''浏览器版本过低不支持文件方法，请升级浏览器或联系客服
			comp_Failed: "组件加载有误", //''组件加载有误，请联系客服

			Import_success: '导入成功', //Import success 导入成功

			// Unknown_image:"未知的图片地址",//''未知的图片地址
			Delete_picture: "删除图片", //删除图片
			sure_del_img: "确定删除此图片吗，删除将无法恢复。是否确定?", //确定删除此图片吗，删除将无法恢复。是否确定?
			Please_upload_format_img: "请上传 .png,.jpeg,.jpg,.gif格式的图片", //请上传 .png,.jpeg,.jpg,.gif格式的图片

			Select_Upload_Excel: "选择Excel并预览",
			Select_Excel: "选择Excel", //选择Excel
			Upload_preview: "上传并预览", //上传并预览
			Download_template: "下载模板", //下载模板
			upload_el_wh_1: "注意",
			upload_el_wh_2: "最多可上传",
			upload_el_wh_3: "个文件",
			upload_el_wh_4: "最大",
			upload_el_wh_5: "文件",
		},

	},

	unit: {

	},
	btn: {
		long_Export_excel: "导出excel",
	},
	topBar: {
		Logout: "退出",
		language: "语言切换",
		Chinese: "中文简体",
		English: "English",

		// switchLangSuccess: "切换语言成功！是否立即刷新本页面？",
		switchLangSuccess: "切换语言成功",
	},
	home: {
		long_1: "物流索赔须知",
		long_2: "所有尾程物流相关的索赔必须在发货后30天内提交到CS@hytxusa.com；",
		long_3: "索赔表格及流程可以向CS@hytxusa.com索取；",
		long_4: "超过时间未申请的尾程物流将无法向HYTX提出索赔；"
	},
	register: {
		registerTitle: "欢迎注册",
		User_Registration: "用户注册",
		Mobile_phone: "手机号",
		placeholder_phone: "请输入手机号",
		China: "中国大陆",
		Verification_Code: "验证码",
		placeholder_Code: "请输入验证码",
		Get_Code: "获取验证码",
		seconds: "秒后可重新获取验证码",
		Password: "密码",
		Duplicate_password: "重复密码",
		Partner_phone: "合伙人手机号",
		Read_agree: "阅读并同意",
		Registration_Agreement: "注册协议",
		register: "注 册",
		account_msg: "已有账号？",
		Sign_in: "登录",
		customer_service_msg: "有任何问题请联系客服",
		Telephone: "电话",
		Online_Service: "在线客服",
		placeholder_areaCode: "请选择手机国际区号",
		placeholder_must_phone: "必须为手机号",
		placeholder_must_pwd: "密码必须为6-18位，且至少包含一位字母和一位数字",
		placeholder_phone_msg: "短信验证码已发送至您的手机，5分钟内有效，请查收！",
		Please_Enter: "请输入",
		// Registration_Agreement: "注册协议",
		ver_re_pwd: "请保持密码一致",

		register_was_successful: "注册成功！",

		loginTitle: "欢迎登录",
		Password_Login: "密码登录",
		Next_automatic_login: "记住密码",
		Sign_in: "登 录",
		Forget_the_password: "忘记密码?",
		New_user_registration: "新用户注册",
		Mobile_scanner_secure_login: "手机扫码，安全登录",
		app_msg_0: "打开",
		app_msg_1: "HYTX APP",
		app_msg_2: "扫码登录",
		ver_login_msg: "请输入正确手机号和密码",

		login_was_successful: "登录成功！",
		login_was_fail: "登录失败！",

		retrieve_password: "找回密码",
		authentication: "验证身份",
		reset_passwords: "重置密码",
		complete: "完成",
		next_step: "下一步",
		new_password: "新密码",
		confirm_password: "确认密码",
		repass_success: "恭喜您，找回密码成功！", //Congratulations! You have successfully retrieved your password! :"恭喜您，找回密码成功！",
		repass_tip1: "请牢记您的新密码", //Please remember your new password:"请牢记您的新密码",
		repass_tip2: "秒后跳转到登录页", //After seconds jump to the login page :"秒后跳转到登录页",
		To_log_in: "去登录",
		repass_fial: "找回失败，请返回重试！", //Failed to retrieve, please return and try again!:"找回失败，请返回重试！",

		reg_success_1: "恭喜您，",
		reg_success_2: "已注册成功！",
		reg_success_3: "下一步，您想？",
		reg_success_4: "开通仓库后，体验仓储、物流、转运、快递等一站式服务",
		reg_success_5: "请妥善保管您在本平台的账号和密码，感谢您选择本平台。",
		reg_success_6: "欢迎注册",
	},
	
	Storage: {
		common: {
			title: '互易仓',
			title2: '互易物流',
			Logout: "退出"
		},
		nav: {
			product: "产品管理", //
			SKU_inventories: "SKU库存", //
			SKU_information: "SKU创建", //
			SKU_water: "SKU流水", //
			SKU_split: "SKU拆分", //
			// prediction : "预报",//
			shipments: "仓库管理", //
			My_packing_list: "入库预报", //
			My_invoice: "转运", //
			A_undertakes_to: "一件代发", //
			report: "仓库报表", //
			My_storage_fee: "我的仓储费", //
			prox: "代理管理",
			order: "订单管理",
			account: "资金&账户",
			workOrder: "工单管理",
		},
		tableColumn: {
			no: "序号", // 序号
			remark: "备注", // 备注
			operation: "操作", // 操作
		},
		skuInfo: {
			Add_SKU: "新建产品", // 添加SKU
			Import_SKU: "批量导入", // 导入SKU
	
			SKU_detail: "SKU详情", //SKU详情
	
			SKU_information: "SKU信息", // SKU信息
	
			cate_placeholder: "选择或搜索商品类目", // 选择或搜索商品类目
			cate_tips: "支持关键字模糊搜索", // 支持关键字模糊搜索
			pic_size_tips: "建议上传尺寸不小于100*100的图，", // 建议上传尺寸不小于100*100的图，
			pic_size_type: "支持png、jpg等格式，大小建议不超过500k;", // 支持png、jpg等格式，大小建议不超过500k;
			sku_tips: "只能是数字字母下划线，不超过30字符", // 只能是数字字母下划线，不超过30字符
	
			category: "产品分类", // 产品分类
			SKU_title: "名称", // SKU名称
			Chinese_title: "中文名称", // SKU中文标题
			English_title: "英文名称", // SKU英文标题
			Chinese: "中文",
			English: "英文",
			SKU_pictures: "SKU图片", // SKU图片
			price: "报关价值", // 价值
			weight: "重量", // 重量
			unit: "单位", // 重量/体积单位
			size: "尺寸", //尺寸
			length: "长", // 长度
			width: "宽", // 宽度
			height: "高", // 高度
			length_width_height: "长*宽*高", //长*宽*高
			safety_stock: "安全库存", // 安全库存
	
			warehouse: "仓库", // 仓库
	
			product_information: "产品信息", //产品信息
			Specification_information: "规格信息", //规格信息
	
			on_hand_inventory: "现有库存", // 现有库位库存
			Current_locked_inventory: "锁定库存", // 当前锁定库存
			Current_available_stock: "有效库存", // 当前有效库存
			unit_price: "单价", // 单价
	
			date: "时间", // 时间
			quantity: "数量", // 数量
			number: "单号", // 单号
			type: "类型", // 类型
			explain: "说明", // 说明
			sku_explain:"SKU建议按照'数字'、'字母'、'-'、'_'组合,不超过20个字符,且保持唯一",
			sku_export_img_explain:"导出全部时，不包含SKU CODE一维码图片；如需导出一维码图片，请导出当前页；",
		},
		packing_list: {
			status: "状态", //状态
			_no: "柜号/跟踪号", // 货柜号
			Create: "创建装箱单", // 创建装箱单
			Import: "导入装箱单", // 导入装箱单
			Departure_port: "出发港口", //出发港口
			arrive_port: "到达港口", //到达港口
			Departure_Date: "出发日期", // 出发日期
			arrive_Date: "到达时间", // 到达时间
			Type: "装箱类型", // 装箱单类型
			quantity: "装箱件数", // 装箱件数
			Check_number: "复核件数", // 复核件数
			attachment: "附件", // 附件
	
			shipments: "发货",
	
			add: "增加", //增加
	
			basic_information: "基本信息", // 基本信息
			detail: "明细", // 明细
			packing_information: "装箱信息", // 装箱信息
			Forwarder_guests: "货代客人", // 货代客人
	
			Other: "其他", // 其他
	
			All: "全部", // 全部
			manuscript: "草稿", // 草稿
			// not_receive:"未收货",// 未收货
			shipped: "未入库", // 已发货
			received: "已入库", // 已收货
	
			sure_shipment: "确定发货吗?", // 确定发货吗?
			
			carton_spec:"外箱规格(当一箱一件时，外箱规格可不填)",
		},
		invoice_list: {
			delivery_number: "发货编号", //:发货编号
			Create_invoice: "创建发货单", //创建发货单
			warehouse_umber: "仓库编号", // 仓库编号
			out_car_Type: "出车方式", // 出车方式
			Expected_delivery_date: "预计到车日期", // 预计到车日期
			Delivery_Warehouse_Number: "发货仓库编号", //发货仓库编号
			Submit_instructions: "提交指令", //提交指令
			Download_lading_bill: "下载提货单", //下载提货单
			Import_SKU_details: "导入SKU明细", // 导入SKU明细
			arrive_storehouse: "到达仓库", // 到达仓库
			FBA_number: "FBA入库申请号", // FBA入库申请号
			Arrive_address: "到达区域", // 到达区域
			Arrive_detailed_address: "到达详细地址", // 到达详细地址
			Volume: "体积(长*宽*高)", // 体积(长*宽*高)
			Add_SKU_manually: "手动新增SKU", // 手动新增SKU
	
			All: "全部", //全部
			manuscript: "草稿", // 草稿
			submitted: "已提交", // 已提交
			Picking: "拣货中", // 拣货中
			Picking_complete: "拣货完成", // 拣货完成
			play_pack: "打托中", // 打托中
			For_outbound: "待出库", // 待出库
			Have_outbound: "已出库", // 已出库
	
			submit_sure: "确定 提交发货指令 吗?", //'确定 提交发货指令 吗?'
		},
		DropShipping: {
			Numbers: "单号", //Numbers单号
			Import: "导入代发单", // 导入代发单
			Batch_submission: "批量提交", // 批量提交
			quantity: "代发数量", // 代发数量
	
			no_SKU: "没有SKU", // 没有SKU
			date: "日期", // 日期
			place_dispatch: "发货地", // 发货地
			place_receipt: "收货地", // 收货地
			Addressee_name: "收件人名称", // 收件人名称
			company: "公司", //公司
			contact_way: "联系方式", //联系方式
			Address_1: "地址1", // 地址1
			Address_2: "地址2", // 地址2
			city: "城市", // 城市
			State: "州/省", // 州/省
			postal_code: "邮编", // 邮编
			country: "国家", // 国家
			submit: "提交", // 提交
	
			All: "全部", // 全部
			manuscript: "草稿", // 草稿
			submitted: "已提交", //已提交
			checked: "已审核", // 已审核
			Picking: "拣货中", // 拣货中
			shipped: "已发货", //已发货
			To_extract: "待提取", //待提取
			Already_extracted: "已提取", //已提取
			To_recall_returns: "待召回|退货", //待召回|退货
			suspend: "拦截", //暂停
			canceled: "已取消", //已取消
	
			sel_tips_1: "请先选择要操作的数据", // 请先选择要操作的数据
			sel_tips_2: "所有选中为草稿状态才可以提交", // 所有选中为草稿状态才可以提交~
			sure_submit_1: "确定提交", //Are you sure to submit 12 pieces of data?//确定提交
			sure_submit_2: "条数据吗?", //pieces of data//条数据吗?
			Parsing_failed: "解析失败，数据量过大", // 解析失败，数据量过大
		},
		whFee: {
			Customer_No: "客户编号", //客户编号
			Fee_Type: "费用类型", //费用类型
			Fee: "费用", //费用
			Fee_description: "费用描述", //费用描述
			quantity: "件数", //件数
			toll_collection_manner: "收费方式", //收费方式
			billing_date: "账单日期", //账单日期
		},
		chart:{
			'DialogInStSkuCharts_1':'SKU变化趋势',
			'DialogInStSkuCharts_2':'库存',
			'DialogInStSkuCharts_3':'总体积',
			'DialogInStSkuCharts_4':'时间',
			
			'OrderStatisticsByMon_1':'订单按月统计',
			'OrderStatisticsByMon_2':'订单按日统计',
			
			'OrderByCharts_1':'一件代发',
			'OrderByCharts_2':'自提单',
			'OrderByCharts_3':'时间',
		}
	
	
	
	},

	// ...zhStorage,
	...locale_cn,
	// ...zhUserCenter,
	// ...zhBuyerCenter,
	// ...zhSellerCenter,
	// ...zhPartnerCenter,
	// ...zhCommercialTenant,
	...zhLocale
}

export default cn